import React from "react";
import {graphql} from "gatsby";

import Layout from "/src/components/layouts/Layout";
import SectionLayoutGrid from "/src/components/layouts/SectionLayoutGrid";

import {LottieAnimation} from "../../components/styled/lotti/LottieAnimations";
import Col from "../../components/styled/grid/Col";
import {Body, Title} from "../../components/styled/typography/Typography";
import {ButtonContainer, ButtonLink} from "../../components/styled/button/ButtonComponents";

import circuit from "/static/assets/animations/circuit/circuit01.json";

const TrainingForm = ({data}) => {

    const {lang} = data.messageSent

    return (
        <Layout lang={lang} seo={{title: lang === "hu" ? "Üzenet elküldve" : "Message sent", isIndexable: false}}>
            <LottieAnimation animationData={circuit} left="true"/>
            <SectionLayoutGrid>
                <Col grid center>
                    <Title color="white" textAlign="center" marginBottom="normal">
                        {lang === "hu" ? "Üzenet elküldve" : "Message sent"}
                    </Title>
                    <Body color="body-text" textAlign="center" marginBottom="small">
                        {lang === "hu" ? "Amint üzenetét feldolgozzuk, kollégáink felveszik Önnel a kapcsolatot!" : "As soon as your message is processed, our colleagues will contact you!"}
                    </Body>
                    <Body color="body-text" textAlign="center" bold>
                        {lang === "hu" ? "Köszönjük!" : "Thank You!"}
                    </Body>
                    <Col grid center>
                        <ButtonContainer>
                            <ButtonLink to={lang === "hu" ? "/" : "/en/"} color="primary-brand">
                                {lang === "hu" ? "Vissza a főoldalra" : "Back to the main page"}
                            </ButtonLink>
                        </ButtonContainer>
                    </Col>
                </Col>
            </SectionLayoutGrid>
        </Layout>
    )
}

export const query = graphql`
   query GetSingleMessageSent($locale: String) {
         messageSent: strapiContactUsForms(lang: { eq: $locale }) {
          lang
      }
   }
 `

export default TrainingForm